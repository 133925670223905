import React, { useState } from 'react';
import PropTypes from 'prop-types';
import img1 from '../../images/gift-card.svg';
import img2 from '../../images/lite.svg';
import img3 from '../../images/super.svg';
import img4 from '../../images/wonder.svg';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
const SingleOffer = props => {
  const [Rname, setRname] = useState('');
  const [Remail, setRemail] = useState('');
  const [Raddress, setRaddress] = React.useState('');
  const [Rphone, setRphone] = useState('');
  const [Sname, setSname] = useState('');
  const [Semail, setSemail] = useState('');
  const [Sphone, setSphone] = useState('');
  const [message, setmessage] = useState('');
  const [selectedDate, setSelectedDate] = React.useState(
    moment(new Date()).add(2, 'days')
  );
  const [SuccessMsg, setSuccessMsg] = useState(null);
  const [sentBy, setsentBy] = React.useState('POST');
  const [productName, setproductName] = React.useState(props.offername);
  const handleDateChange = date => {
    setSelectedDate(date);
  };
  function placeOrder(e) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (
      Rname.length < 1 ||
      Remail.length < 4 ||
      Rphone.length < 4 ||
      Sname.length < 1 ||
      Semail.length < 4 ||
      Sphone.length < 4 ||
      message.length < 4
    ) {
      setSuccessMsg('fieldsMissing');
      setTimeout(() => {
        setSuccessMsg(null);
      }, 6000);
    } else {
      var dateSelected = new Date(selectedDate);
      const url = process.env.GATSBY_API_URL + '/gift';
      const data = {
        Rname: Rname,
        Rnumber: Rphone,
        Remail: Remail,
        Raddress: Raddress,
        Sname: Sname,
        Snumber: Sphone,
        Semail: Semail,
        message: message,
        sentBy: sentBy,
        date: dateSelected,
        product: productName,
      };
      const headers = {
        'Access-Control-Allow-Origin': '*',
      };

      axios.post(url, data, { headers: headers }).then(res => {
        if (res.data === 'Success') {
          setRname('');
          setRemail('');
          setRphone('');
          setRaddress('');
          setSname('');
          setSemail('');
          setSphone('');
          setmessage('');
          setSuccessMsg(true);
          setTimeout(() => {
            setSuccessMsg(null);
          }, 360000);
        } else {
          setSuccessMsg(false);
          setTimeout(() => {
            setSuccessMsg(null);
          }, 36000);
        }
      });
    }
  }
  return (
    <div id="wrapper" style={{ color: '#000' }}>
      {/* Start */}
      <div className="Offer_wrapper">
        <div className="container-fluid Offer_container">
          <div className="row">
            <img
              className={'Offer_topPageImage'}
              src={img1}
              alt="Partner Image"
            />
            <br />
            {/* <div className={'col-md-12 colmTextHeading'}>
                            <h1> Set Someone Free</h1>
                            <p>this holiday season</p>
                            <span>Set your friends and family members free from doing<br/> their laundry this holiday season.</span>
                        </div> */}
            <div className={'col-md-12 offer-mobile-wrapper'}>
              <div
                className={
                  'row offers-three-colmswrapper offer-single-product-wrapper'
                }
              >
                <div
                  className={'btn-offer back-btn'}
                  onClick={props.onchangeSelection}
                >
                  <i className={'fa fa-angle-left fa-1x'}></i>
                  &nbsp;&nbsp;&nbsp;Back
                </div>
                <div
                  className={'col-md-4 col-sm-12 Offer_col1 Offer_col-single'}
                >
                  <br />
                  <br />
                  <h2 className={'offer-single-product-heading'}>
                    Enter your details
                  </h2>
                  <p
                    className={'offer-single-product-para Offer-Chose-question'}
                  >
                    Have questions? <a href="tel:+16477224585">Call us.</a>
                  </p>
                  <br />
                  <br />
                  <br />
                  {props.noofOrder == '1' ? (
                    <img src={img2} className={'Offer_col-single-img'} />
                  ) : (
                    ''
                  )}
                  {props.noofOrder == '2' ? (
                    <img src={img3} className={'Offer_col-single-img'} />
                  ) : (
                    ''
                  )}
                  {props.noofOrder == '3' ? (
                    <img src={img4} className={'Offer_col-single-img'} />
                  ) : (
                    ''
                  )}
                  <div className={'Offer-Col-Content'}>
                    <div className={''}>
                      <h3>{props.offername}</h3>
                      <p>
                        Coupon valid for {props.noofOrder} garbage bag
                        {props.noofOrder > 1 ? 's' : ''}
                      </p>
                      <p className={'offers-includes'}>Includes:</p>
                      <ul>
                        <li>Door Pick up</li>
                        <li>Wash, Dry and Fold</li>
                        <li>Sort</li>
                        <li>Door Drop-off</li>
                      </ul>
                      {/* <p>1 full garbage bag { props.noofOrder > 1 ? "per order": ""}</p> */}
                      <br />
                      {/* <h4 className={'Offer-col-price'}><b>{props.price}</b></h4> */}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    'col-md-4 col-sm-12 Offer_col2 Offer_col-single formCol'
                  }
                >
                  <br />
                  <br />
                  <div className={'row offer-row-whoisitFor'}>
                    <h3>Who is it for?</h3>
                    <div className={'col-md-12 formCol-Row formCol-Row-first'}>
                      <input
                        class="colHalf first"
                        title="Recipient Name"
                        required
                        id="fname"
                        value={Rname}
                        name="name"
                        type="text"
                        placeholder="Recipient Name *"
                        onChange={event => {
                          setRname(event.target.value);
                        }}
                      />
                    </div>
                    <div className={'col-md-12 formCol-Row formCol-Row-second'}>
                      <input
                        class="colHalf last"
                        title="Recipient Email ID"
                        required
                        id="lname"
                        value={Remail}
                        name="name"
                        type="email"
                        placeholder="Recipient Email ID *"
                        onChange={event => {
                          setRemail(event.target.value);
                        }}
                      />
                    </div>
                    <div className={'col-md-12 formCol-Row formCol-Row-second'}>
                      <input
                        class="colHalf last"
                        title="Recipient Postal Address"
                        required
                        id="lname"
                        value={Raddress}
                        name="name"
                        type="text"
                        placeholder="Recipient Postal Address"
                        onChange={event => {
                          setRaddress(event.target.value);
                        }}
                      />
                    </div>
                    <div className={'col-md-12 formCol-Row formCol-Row-second'}>
                      <input
                        class="colHalf last"
                        title="Recipient Phone no."
                        required
                        id="lname"
                        value={Rphone}
                        name="name"
                        type="text"
                        placeholder="Recipient Phone no.  *"
                        onChange={event => {
                          setRphone(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={'row offer-row-whoisitForm'}>
                    <div className={'col-md-12 formCol-Row formCol-Row-first'}>
                      <h3>Who is it from?</h3>
                      <input
                        class="colHalf first"
                        title="Sender Name"
                        required
                        id="fname"
                        value={Sname}
                        name="name"
                        type="text"
                        placeholder="Sender Name *"
                        onChange={event => {
                          setSname(event.target.value);
                        }}
                      />
                    </div>
                    <div className={'col-md-12 formCol-Row formCol-Row-second'}>
                      <input
                        class="colHalf last"
                        title="Sender Email ID"
                        required
                        id="lname"
                        value={Semail}
                        name="name"
                        type="email"
                        placeholder="Sender Email ID *"
                        onChange={event => {
                          setSemail(event.target.value);
                        }}
                      />
                    </div>
                    <div className={'col-md-12 formCol-Row formCol-Row-second'}>
                      <input
                        class="colHalf last"
                        title="Sender Phone Number"
                        required
                        id="lname"
                        value={Sphone}
                        name="name"
                        type="text"
                        placeholder="Sender Phone Number *"
                        onChange={event => {
                          setSphone(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={'row offer-row-whoisitmessage'}>
                    <h3>Want to add a personal message?</h3>
                    <div className={'col-md-12 formCol-Row formCol-Row-first'}>
                      <textarea
                        name="textarea"
                        required
                        id=""
                        cols="30"
                        rows="17"
                        placeholder="Enter your Message *"
                        value={message}
                        title="Your Question"
                        onChange={event => {
                          setmessage(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    'col-md-4 col-sm-12 Offer_col2 Offer_col-single formCol'
                  }
                >
                  <br />
                  <br />
                  <div className={'row offer-row-whoisitFor offer-row-send'}>
                    <h3>How would you like to send it?</h3>
                    <div className={'col-md-12 formCol-Row formCol-Row-first'}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={
                          sentBy === 'POST'
                            ? 'selection-post selected-option'
                            : 'selection-post'
                        }
                        onClick={event => {
                          setsentBy('POST');
                        }}
                      >
                        {' '}
                        POST
                      </Button>
                      <Button
                        variant="contained"
                        color="#fff"
                        className={
                          sentBy === 'MAIL'
                            ? 'selection-mail selected-option'
                            : 'selection-mail'
                        }
                        onClick={event => {
                          setsentBy('MAIL');
                        }}
                      >
                        {' '}
                        MAIL
                      </Button>
                    </div>
                  </div>
                  <br />
                  <div className={'col-md-12 formCol-Row formCol-Row-first'}>
                    <div className={'row offer-row-whoisitFor offer-row-send'}>
                      <h3>When do you want to send it?</h3>
                      <div
                        className={
                          'col-md-12 formCol-Row dateRow formCol-Row-first'
                        }
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          className={'selection-post selected-option'}
                          onClick={event => {
                            setSelectedDate(moment(new Date()));
                          }}
                        >
                          {' '}
                          NOW{' '}
                        </Button>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <Grid
                            className={'offer-single-dateContainer'}
                            container
                            justify="space-around"
                          >
                            <KeyboardDatePicker
                              margin="normal"
                              id="date-picker-dialog"
                              format="DD/MM/yyyy"
                              minDate={moment(new Date())}
                              value={selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className={'col-md-12 formCol-Row formCol-Row-first'}>
                    <div className={'row offer-row-whoisitFor'}>
                      <br />
                      <h1 className={'Offer-col-price'}>
                        <b>{props.price}</b>
                      </h1>
                      <div
                        className={'col-md-12 formCol-Row formCol-Row-first'}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          className={'selection-post selected-option'}
                          onClick={placeOrder}
                        >
                          Place Order
                        </Button>
                        <br />
                        {SuccessMsg === 'fieldsMissing' ? (
                          <p style={{ color: 'red', fontSize: '21px' }}>
                            Please Fill All Mandatory Fields!
                          </p>
                        ) : (
                          ''
                        )}
                        {SuccessMsg === true ? (
                          <p
                            style={{
                              color: '#000',
                              fontSize: '22px',
                              fontWeight: 'bold',
                            }}
                          >
                            An invoice is sent to your Email ID. Make payment to
                            receive your coupon!
                          </p>
                        ) : (
                          ''
                        )}
                        {SuccessMsg === false ? (
                          <p
                            style={{
                              color: '#000',
                              fontSize: '22px',
                              fontWeight: 'bold',
                            }}
                          >
                            Some Error Occured Please Try Again!
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className={'bootomText'}>
                <br />
                <br />
                *An invoice will be emailed to you for payment. Once payment is
                made, the coupon code will be sent to you and the recipient as
                per your preferred delivery instructions. The coupon can only be
                used while placing orders at LaundryToGo.ca and will only work
                for the number of times it is intended. Please refer to the
                coupons details above.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* End */}
    </div>
  );
};
export default SingleOffer;
